

.div-table {
  display: table;         
  width: 100%;                  
}
.div-table-row {
  display: table-row;
  width: 100%;
  clear: both;
}

.div-table-col {
  float: left; /* fix for  buggy browsers */
  /* display: table-column;           */
  background:white;
  border:solid 1px silver;  
  padding:0.8em 0.8em 0.8em 0.8em;    
  width:99%;
  margin-bottom:2em;  
}

.div-table-col h4 {
  margin:0 0 -0.5em 0;
  font-size: 11pt;
  font-weight: bold;
}



.table-container {
  width: 100%;
  overflow-x: auto;
}

.simple-table {
  width: 100%;
  border-collapse: collapse;
}

.simple-table th, .simple-table td {
  padding: 8px 12px;
  text-align: left;
  white-space: nowrap; /* Prevent wrapping */
  border-bottom: 1px solid #ddd;
}

.simple-table th {
  background-color: seashell;
}

/* Add this rule to make sure the last column is always shown */
.simple-table th:last-child,
.simple-table td:last-child {
  min-width: 50px; /* Adjust as needed */
  text-align: center;
}

/* Media query for smaller screens */
@media screen and (max-width: 768px) {
  .simple-table th, .simple-table td {
      padding: 6px 10px;
  }

  .simple-table th:nth-child(1),
  .simple-table td:nth-child(1),
  .simple-table th:nth-child(2),
  .simple-table td:nth-child(2),
  .simple-table th:nth-child(3),
  .simple-table td:nth-child(3) {
      width: auto; /* Allow these columns to adjust */
  }

  .simple-table th,
  .simple-table td {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      border-bottom: 1px solid #ddd;
  }
}

.simple-table tbody tr:hover {
  background-color: #63cae92a; /*  background on hover */
}

.simple-table tbody tr.selectable-row:hover {
  cursor: pointer; /* Add a pointer on hover */
}


.col-md-12 {
  background-color: white;
  padding:1em;
}

.divPrintIcon{
  float:right;
}

.divPrintIcon img{
  height: 50px;
  cursor: pointer;
  margin-top: -1.5em;
  margin-right:2em;
}


