.row {
    margin-left:1em;
}

.col-md-1, .col-md-2, .col-md-3, .col-md-4 {
    background-color: white;
    padding:1em;
}

.btnBig {
    margin-top: 1.5em;
}

.divPrintIcon{
    float:right;
}

.divPrintIcon img{
    height: 50px;
    cursor: pointer;
    margin-top: -1.5em;
    margin-right:2em;
}
 