.divFormLayout  {
  margin: 1em;
  font-size: 11pt;
  font-weight: 500;
}

.divFormLayout .form-check input[type=text]{
    width:100%;
}

.divFormLayout .form-check input[type=checkbox]{
  visibility: visible;
  opacity: 100%;
  z-index: 999;
  height: 15pt;
  width: 15pt;
  margin: 4px 0 0 0;  
}

.divFormLayout .form-label label {
  margin-top: 1em;
}

.divFormLayout textarea.form-control {
  padding: 0.5em;
  border: solid 1px silver;
}

.divBackground {
  background-color: white;
  padding: 1em;
  margin:0em;

}


.autocomplete {
  transition: .5s;
  z-index: 5;
  position: absolute;
  max-height: 0;
  box-sizing: border-box;
  top: 30px;
  left: 25px;
  width:35%;

}

.autocomplete ul {
  border: solid 1px silver;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
  -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
  -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
  background-color: cornsilk;
  list-style-type: none;
  padding:1em;
}

.autocomplete ul li {
  text-align: center;
  cursor: pointer;
  margin-bottom: 0.3em;
  border-bottom: solid 1px darkgrey;

}