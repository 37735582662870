.divList li {
    width:100%;
    text-align: left;
    border-right: none;
    padding: 12px 15px;
    border-bottom: 1px solid #ddd;
    height:100%;    
}

.divList li:hover {
    background-color: #63cae92a;
}

.divList li .spGuestItem{
    cursor: pointer;
}

.divList input[type=checkbox]{
    margin-right:0.5em
}

.div-table-today {
    display: table;
    margin: 0 auto;
    padding: 0;
}

.div-table-today li {
    float:left;
    padding: 0 1.5em 0 1.5em;
}

#lnkRoomsAvailability {
    float: right;
    color: Blue;
    margin-top: 1em;
    margin-right: 1em;
}
  
.block-rooms {
    margin-top: 1em;
    font-size: 0.9em;
}

  