
.ml-auto mr-auto col-md-4{
  max-width: 100%;
}

.card-sz1{
  width:40%;
  padding:1em;
}

.card-sz2{
  width:82%;
  padding:1em;
}

.card-sz3{
  width:20%;
  padding:1em;
}

.card-report{
  width:auto;
  padding:0 0.4em 0 0.4em;
}

.card-body{
  padding: 0;
}

.spRound{
  height:40px;
  width:40px;
  background-color: bisque;
  border-radius: 50%;
  display: inline-block;
  margin-right:0.5em;
  padding-top:0.5em;
}

.divCountsLabel{
  font-weight:500;
  font-variant-caps: petite-caps;
  font-size:15pt;
  color:cadetblue;
  margin-bottom: 0.5em;
}

.divSubTextContainer
{
  width:100%;
  font-size: 15pt;
  font-weight:300;
}

.divCounts{
  font-weight: 700;
  color:chocolate;
  font-size: 24pt;
  margin-right: 0.25em;
  margin-left:0.25em;
  vertical-align: bottom;
}

.divDashboardHeader{
  font-size: 18pt;
  font-variant-caps: all-petite-caps;
  margin-bottom: 2em
}


.result-span {
  text-align: left;
  display: block;
  white-space: pre-wrap;
  overflow: visible;
  text-overflow: ellipsis;
}

.result-type-span {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 8pt;
  color: #555e68;
  display: block;
  padding: 0.5em 0 0.5em 0;
  white-space: pre-wrap;
  overflow: visible;
  text-overflow: ellipsis;
}

.result-room-mate-span {
  white-space: pre-wrap;
  font-size: 9pt;
  color: #555e68;
  padding: 0.5em 0 0.5em 0;
 }

.divDetails {
  margin-top: 2em;
}

.divDetails .card .card-header {
  font-size:20pt;
}

.divDetails .card .card-body {
  margin-top: -1em;
  margin-left: 1.5em;
  margin-right:1em;
  font-variant-caps: small-caps;
}


.divDetails .card .card-body .divTicketTypes {
  color:chocolate
}

.divDetails form {
  margin-top: 1.5em;
  color: black;
  font-weight: 400;
}

.divDetails .form-check input[type=checkbox]
{
  visibility: visible;
  opacity: 100%;
  z-index: 999;
  height: 15pt;
  width: 15pt;
  margin: 4px 0 0 0;  
}

.divDetails .form-label label {
  margin-top: 1em;
}

.divDetails button {
  width:40%;
  float:right;
}


.bg-success {
  font-size: 10pt;
  text-align: center;
  padding-top: 1em;
  padding-bottom: 1em;
  padding-left: 1em;
  padding-right: 1em;
  background-color: #9DE099 !important;
}


.bg-warning {
  font-size: 10pt;
  text-align: center;
  padding-top: 1em;
  padding-bottom: 1em;
  padding-left: 1em;
  padding-right: 1em;
  background-color: #FCAE1E !important;
}

.bg-none {
  font-size: 10pt;
  text-align: center;
  padding-top: 1em;
  padding-bottom: 1em;
  padding-left: 1em;
  padding-right: 1em;
  background-color: #F0F0F0;
}

.divLegendParent{
  display: inline-flex;
  width:100%;
  margin-top: 1em;
}

.divLegendParent .divLegend{
  height:20px;
  width:20px;
  margin-right: 5px;
}

.divLegendParent .divLegendCompleted{
  background-color: #9DE099;
}

.divLegendParent .divLegendPending{
  background-color: #FCAE1E;
}

.divLegendParent .divLegendNA{
  background-color: #F0F0F0;
}

.spDayPassDates{
  font-size: 10pt;
  color: cornflowerblue;
  font-weight: bold;
}

.sticky-col {
  position: sticky;
}

.first-col {
  width: 100px;
  min-width: 100px;
  left: 0px;
}

.second-col {
  width: 200px;
  min-width: 150px;
  left: 100px;
}

.table{
  color: #555e68;
}

.table tr:hover{
    background-color: gainsboro;
}

.tbl-header th{
  background-color: wheat;
  text-transform: uppercase;
  text-align: center;
  font-weight: 700;
}

textarea {
  width:100%;
}

#divNonIndianDetails {
  background-color: antiquewhite;
  padding: 1em;
}

#divIndianDetails {
  background-color: antiquewhite;
  padding: 1em;
}

#slRoomBlocks{
  width: 150px;
}

.trRoomRoster td{
  vertical-align: middle;
  text-align: center;
}

#divDeleteIcon{
  float:right;
  margin-top: -2.2em;
  cursor: pointer;
}


.nav-link {
  cursor: pointer;
}

.guestRoomSearch{
  font-size: 14pt;
  width:90%;
}


/*new css for the pn2.0*/ 

body {
  font-family: 'Lucida Sans Unicode', 'Lucida Sans';  
  background-color: #f3efec;
}

.btnBig {    
  background-color: rgb(226, 132, 55);  
  color: #ffffff !important;
  cursor: pointer;
  display: inline-block;
  font-weight: 600;
  height: 2.2em;
  line-height: 2em;
  padding: 0 1.2em;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  border: 0;
  -webkit-appearance: none;
  box-sizing: border-box;
  font-size: 16px;
  border-radius: 5px;
}

.divPageContainer{
  margin: 1em 3em 1em 3em;
  width:96%;
  padding:0; 
}

.divPageContainer h3{
  font-size: 18pt;
  color:black;
  font-variant-caps: all-petite-caps;
  margin-bottom: 0.5em;
} 



.divSectionHeader h4{
  margin: 0;
  padding:0;
  font-size: 16pt;  
}

.div-table-col ul {
  list-style-type: none;
  padding:0;
}

.div-table-col li {
  float: left;
  border-right: 1px solid silver;
  color: black !important;
  text-align: center;
  -webkit-appearance: none;
  box-sizing: border-box;
  height:55px;
  word-wrap: normal;
}

.spCount{
  font-size:16pt;
  font-weight:bolder;
  color:#719F5A;
}

.spNameCount{
  font-size:9pt;
}


.reservation-item.general{
    padding: 4px;
    border: solid 1px yellowgreen;
    background-color: beige;
}

.reservation-item.retreat{
  padding: 4px;
  border: solid 1px brown;
  background-color: wheat;
}

.reservation-item.sanskara{
  padding: 4px;
  border: solid 1px lightskyblue;
  background-color: #c8d0d9;
}

.reservation-item.agent{
  padding: 4px;
  border: solid 1px yellowgreen;
  background-color: beige;
}

.reservation-item.course{
  padding: 4px;
  border: solid 1px teal;
  background-color: gainsboro;
}

.reservation-item.katha{
  padding: 4px;
  border: solid 1px skyblue;
  background-color: mintcream;
}

.reservation-item.sevak{
  padding: 4px;
  border: solid 1px darkkhaki;
  background-color: khaki;
}

.reservation-item.new{
  padding: 4px;
  font-variant-caps: all-small-caps;
  font-size: x-large;
  font-weight: bolder;
  color: rgb(42, 145, 209);
  /* color: rgb(57, 113, 177);  */
}

.reservation-item.confirmed{
  padding: 4px;
  font-variant-caps: all-small-caps;
  font-size: x-large;
  font-weight: bolder;
  color: orange;
}

.reservation-item.allocated{
  padding: 4px;
  font-variant-caps: all-small-caps;
  font-size: x-large;
  font-weight: bolder;
  color: teal;

}

.reservation-item.checkedin{
  padding: 4px;
  font-variant-caps: all-small-caps;
  font-size: x-large;
  font-weight: bolder;
  color: rgb(98, 138, 90);
}

.reservation-item.checkedout{
  padding: 4px;
  font-variant-caps: all-small-caps;
  font-size: x-large;
  font-weight: bolder;
  color: rgb(138, 130, 130);

}

.reservation-item.cancelled{
  padding: 4px;
  font-variant-caps: all-small-caps;
  font-size: x-large;
  font-weight: bolder;
  color: firebrick;

}

.reservation-item.noshow{
  padding: 4px;
  font-variant-caps: all-small-caps;
  font-size: x-large;
  font-weight: bolder;
  color: firebrick;
}

.reservation-item.denied{
  padding: 4px;
  font-variant-caps: all-small-caps;
  font-size: x-large;
  font-weight: bolder;
  color: rgb(94, 9, 9);
}

.room-status.autoassigned{
  padding: 4px;
  font-variant-caps: all-small-caps;
  font-size: large;
  font-weight: bolder;
  color: rgb(42, 145, 209);
} 

.room-status.allocated{
  padding: 4px;
  font-variant-caps: all-small-caps;
  font-size: large;
  font-weight: bolder;
  color: teal;
}

.room-status.checkedin{
  padding: 4px;
  font-variant-caps: all-small-caps;
  font-size: large;
  font-weight: bolder;
  color: darkgreen;
}

.room-status.checkedout{
  padding: 4px;
  font-variant-caps: all-small-caps;
  font-size: large;
  font-weight: bolder;
  color: rgb(138, 130, 130);
}

.room-status.housekeepingcompleted{
  padding: 4px;
  font-variant-caps: all-small-caps;
  font-size: large;
  font-weight: bolder;
  color: purple;
} 

.room-status.cancelled{
  padding: 4px;
  font-variant-caps: all-small-caps;
  font-size: large;
  font-weight: bolder;
  color: firebrick;
}

.room-status.noshow{
  padding: 4px;
  font-variant-caps: all-small-caps;
  font-size: large;
  font-weight: bolder;
  color: rgb(221, 128, 67);
}

.room-availability.available{
  padding: 4px;
  font-variant-caps: all-small-caps;
  font-size: large;
  font-weight: bolder;
  color: rgb(98, 138, 90);
}

.room-availability.blocked{
  padding: 4px;
  font-variant-caps: all-small-caps;
  font-size: large;
  font-weight: bolder;
  color: rgb(138, 130, 130);
}

.imgClose{
  cursor: pointer;
  height: 50px;
  margin: -4em 0 0em 0;
  float:right;
}

.divFloatRight{
  float:right;
} 

h2 span {
  font-size: 12pt;
  font-weight: 500;
  color: #555e68;
  margin-left:1em
}

