html, body {
    height: 100%;
    margin: 0;
  }
  
  .page-header {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh; /* Use viewport height */
    background-color: #f3efec; /* assuming your background color */
  }
  
  .content {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 600px; /* Adjust this value based on your design */
    padding: 1em; /* Adjust padding as needed */
  }
  