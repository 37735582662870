
  .divBlocks{
    border-bottom:solid 1px darkgoldenrod;
    padding:1em;
    margin-bottom:1em;
    width:100%;
    display: inline-table;
  }
  
  .divBlocks h4{
    width:80%;
    margin:-1.6em 0 0 1.5em;
  }
  
  .divBlocks ul{
    margin-top:0.5em;
    list-style-type: none;
    padding: 0;
  }
  
  .divBlocks li{
    margin:1em;
    padding:1em;
    border: solid 1px khaki;
    background: papayawhip;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    word-wrap: normal;
    -webkit-appearance: none;
    box-sizing: border-box;
    color: #000 !important;
    float: left;
    height: 55px;
    text-align: center;
  }

  .divBlocks li input[type=checkbox]{
    margin-right:0.5em
  }
  
  .sp-block-imgs{
    margin:0 0 0 1em;
  }
  
  .sp-block-imgs img{
    margin:-0.5em 0 -0.3em 0;
  }
  
  .div-block-totals, .div-block-beds{
    float:right;
    margin:-1em 1em 0 0;
    font-weight:bolder;
    font-size:15pt;
    text-align: center;
  }
  
  .sp-block-total, .sp-block-beds{
    margin:0.8em 0 0 1em;
    font-weight: bold;
    color:#278EDE;
  }
  
  .spEventHallLegend{
    font-size:10pt;
  }
  
  .grand-total{
    font-size:12pt;
    margin-top:-2.7em;
  }




