/* css for Confirm Reservations page */

.txtRoomCategory{
  width: 25%;
  display: inline;
  border:solid 1px darkgoldenrod;
} 

.div-grand-beds{
  float: none;
  margin-left: 1em;
  border: 1px solid darkorange;
  border-block-width: initial;
  padding: 0.3em;
  font-weight: bolder;
}

.two-column-list {
  display: grid;
  grid-template-columns: repeat(5, 1fr); /* Creates 2 equal-width columns */
  gap: 10px; /* Optional spacing between items */
  list-style-type: none; /* Removes default list styling */
  padding: 0; /* Removes default padding */
  margin: 0; /* Removes default margin */
  text-align: center;
  font-size: 9pt;
}

.two-column-list li {
  background-color: linen; /* Optional background color for clarity */
  padding: 10px 0 10px 0; /* Optional padding for spacing */
  box-sizing: border-box; /* Ensures padding is included in the width */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  border: solid 1px peachpuff;
}

.page-container {
  padding:1em;
}
  
.page-container h2 {
  margin-bottom: 1em;
  color:saddlebrown;
  text-align: center;
  margin-bottom: 20px;
}
    
  h4 {
    margin-bottom: 10px;
  }
  
  .confirmation-section {
    text-align: center;
    margin-top:3em;
  }
  
  .confirmation-section label {
    margin: 0 10px;
  }
  
  .send-email {
    margin-top: 10px;
    font-size: 12pt;
    font-weight: bolder;
  }
  
  .save-button-container {
    text-align: right;
  }
  
  .content-container {
    margin-top:-1.5em;
    display: flex;
    gap: 20px;
    width: 100%;
    font-size: 11pt;
  }
  
  .left-section {
    width:15%;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .center-section {
    flex: 1;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 15px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }

  .right-section {
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 15px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .div-container {
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    position: relative;
    justify-content: space-between;
    font-size: 13pt;
  }

  .highlightNo{
    font-size: 1.3em;
    padding: 10px;
    width: 25px;
    height: 25px;
    margin-right:0.5em;
    color: white;
    font-weight: bolder;
    margin-right: 0.5em;
    border-radius: 10px;
  }

  .div-box .highlightNo1 {
    background-color: #4CAF50;
  }
  
  .div-box .highlightNo2 {
    background-color: coral;
  }
  

  .highlightNo3 {
    color:#278ede
  }

  .highlightNo4 {
    background-color: sienna;
  } 

  .separator {
    width: 1px;
    height: 100%;
    background-color: #ddd;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  .dates {
    display:inline-block;
    overflow: hidden;
  }
  
  .spanBold{
    font-weight: bolder;
    /* overflow: hidden;
    text-overflow: ellipsis;
    display:inline-block;
    max-width: 100%; */
  }
  
  .spanNormal {
    font-size: 1em;
    color: #555;
  }
  
  .spanSimple {
    font-size: 1em;
    color: #aaa;
  }

  .spanSmall {
    font-size: 9pt;
  }
  


  @media (max-width: 1280px) {
    .div-container {
      flex-direction: column;
      align-items: flex-start;
    }
    
    .separator {
        display: none;
    }
  
    .div-box {
      margin-bottom: 10px;
    }
  
    .highlightNo {
      width: auto;
      height: auto;
    }
  }
  
  .info-item {
    margin-bottom: 15px;
  }
  
  .info-item label {
    font-weight: bold;
    display: inline-block;
    width: 130px;
  }
  
  .info-item span {
    color: #555;
  }
  
  
  .info-box {
    width: 100%;
    text-align: left;
  }
  
  
  .info-item label {
    font-weight: bold;
    display: inline-block;
  }
  
  .info-item span {
    color: #555;
  }

  .imgGuestIcons{
    height: 40px;
    cursor: pointer;
  }


  .custom-radio {
    position: relative;
    display: inline-block;
    padding-left: 35px; /* Space for the custom radio button */
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 16px;
}

.custom-radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.custom-radio .radio-button {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px; /* Size of the circle */
    width: 20px;  /* Size of the circle */
    border-radius: 50%;
    background: #fff; /* Default background */
    transform: scale(1.5); /* Scale up the radio button */
}

.confirm{
  border: 2px solid green; /* Border color */
}

.confirm:after{
  background: green; /* Color of the inner dot */
}

.deny {
  border: 2px solid #f44336; /* Border color */
} 

.deny:after{
  background: #f44336; /* Color of the inner dot */
}

.custom-radio .radio-button:after {
    content: "";
    position: absolute;
    display: none;
    border-radius: 50%;
    width: 12px; /* Size of the inner dot */
    height: 12px; /* Size of the inner dot */
    top: 2px;
    left: 2px;
}

.custom-radio input:checked ~ .radio-button:after {
    display: block;
}

  