.vertical-line-item {
    position: relative; /* Ensure that the vertical line is positioned correctly */
    padding-left: 5px; /* Optional: Add padding to ensure content is not too close to the line */
}

.vertical-line-item::before {
    content: ''; /* No textual content; it's just a visual line */
    position: absolute; /* Position the line absolutely within the parent element */
    height: 100%; /* Full height of the parent element */
    width: 5px; /* Line width */
    background-color: darkgreen; /* Line color */
    left: 0; /* Align to the left of the parent element */
    top: 0; /* Align to the top of the parent element */
}
